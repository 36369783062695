.loaderParent {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 100%;
    background: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .content {
    margin: 0;
    padding: 0;
    font-family: ‘Arial Narrow’, sans-serif;
    font-weight: 100;
    font-size: 1.1em;
    color: #191a1a;
  }
  
  .spanLine {
    position: relative;
    top: 0.63em;  
    display: inline-block;
    text-transform: uppercase;  
    opacity: 0;
    transform: rotateX(-90deg);
  }
  
  .let1 {
    animation: drop .8s ease-in-out infinite;
    animation-delay: .1s;
  }
  
  .let2 {
    animation: drop .8s ease-in-out infinite;
    animation-delay: .2s;
  }
  
  .let3 {
    animation: drop .8s ease-in-out infinite;
    animation-delay: .3s;
  }
  
  .let4 {
    animation: drop .8s ease-in-out infinite;
    animation-delay: .4s;
  
  }
  
  .let5 {
    animation: drop .8s ease-in-out infinite;
    animation-delay: .5s;
  }
  
  .let6 {
    animation: drop .8s ease-in-out infinite;
    animation-delay: .6s;
  }
  
  .let7 {
    animation: drop .8s ease-in-out infinite;
    animation-delay: .7s;
  }

  @keyframes drop {
    10% {
        opacity: 0.5;
        top: -3.78em;
    }
    20% {
        opacity: 1;
        /* top: 3.78em; */
        top: 0;
        transform: rotateX(-360deg);
    }
    80% {
        opacity: 1;
        /* top: 3.78em; */
        top: 0;
        transform: rotateX(-360deg);
    }
    90% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        /* top: 6.94em; */
        top: 3.78em;
    }
}
  
  /* @keyframes drop {
      10% {
          opacity: 0.5;
      }
      20% {
          opacity: 1;
          top: 3.78em;
          transform: rotateX(-360deg);
      }
      80% {
          opacity: 1;
          top: 3.78em;
          transform: rotateX(-360deg);
      }
      90% {
          opacity: 0.5;
      }
      100% {
          opacity: 0;
          top: 6.94em
      }
  } */