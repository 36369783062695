@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");
html {
  font-family: "Lato", "Roboto", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.css-10hburv-MuiTypography-root {
  font-size: 0.9rem !important;
}
